<template>
  <div class="home-content" style="">

    <!-- <div class="main-bg-img"></div> -->

    <v-row>

      <v-col cols="12" sm="11" md="10" class="mx-auto" style="z-index:1!important">
        <v-img src="../assets/img/logo-genie2.png" width="600" class="main-logo light mx-auto"></v-img>
        <v-img src="../assets/img/logo-genie-dark.png" width="600" class="main-logo dark mx-auto"></v-img>
        <!-- <h1 class="mt-5 mb-0 font-flower main-title d-none d-md-inline-block" style="">{{ $store.state.app.siteName }}</h1>
        <h1 class="mt-5 mb-0 font-flower main-title d-inline-block d-md-none" style="">Nanoug'</h1>
        <h1 class="mb-0 font-flower main-title d-inline-block d-md-none" style="margin-top:-35px;">galerie</h1> -->
       
       
        <!-- <p class="font-flower main-home-subtitle px-5" >
          Plongez dans un bain<br><span class="d-inline-block">de couleurs et de bonne humeur !</span>
        </p> -->
      </v-col>

      <!-- SUB MENU BTN -->
      <v-col cols="12" sm="11" md="10" class="mx-auto">
        
          <!-- <v-divider></v-divider> -->

          <v-toolbar color="transparent" elevation="0" class="d-none d-md-inline">
            <v-spacer/>
            <v-btn elevation="0" text x-large rounded class="btn-home" to="/gallery">Galerie</v-btn>
            <v-btn elevation="0" text x-large rounded class="btn-home" to="/album">Albums</v-btn>
            <v-btn elevation="0" text x-large rounded class="btn-home" to="/shop">Boutique</v-btn>
            <v-btn elevation="0" text x-large rounded class="btn-home" to="/contact">Expos</v-btn>
            <v-spacer/>
            <!-- <v-spacer/>
            <v-btn fab small color="white" class="d-none d-sm-flex ml-1" target="_blank" href="https://www.facebook.com/daniele.goguet">
              <v-icon  color="grey darken-4">mdi-facebook</v-icon>
            </v-btn>
            <v-btn fab small color="white" class="d-none d-sm-flex ml-1" to="/contact"><v-icon  color="grey darken-4">mdi-at</v-icon></v-btn> -->
          </v-toolbar>
          <v-row class="d-md-none text-center">
            <v-col cols="6">
              <v-btn elevation="0" outlined x-large rounded class="btn-home" to="/gallery">Galerie</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn elevation="0" outlined x-large rounded class="btn-home" to="/album">Albums</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn elevation="0" outlined x-large rounded class="btn-home" to="/shop">Boutique</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn elevation="0" outlined x-large rounded class="btn-home" to="/contact">Expos</v-btn>
            </v-col>
          </v-row>

          <!-- <v-divider></v-divider> -->

      </v-col>
      
      <v-col cols="10" sm="10" class="mx-auto px-0 px-md-4">
        
        <v-row v-if="$store.state.app.data.config.length > 0">
          <v-col cols="12" lg="8" class="px-0 px-md-5">
            <v-alert v-if="configHome2 != null && configHome2.text != ''" color="red darken-3" 
                     outlined style="font-weight: 600 !important;" class="d-inline-block" 
                     v-html="nl2br(configHome2[0].text)"/>
            <p style="font-weight: 600 !important;" 
               v-if="configHome1 != null && configHome1.text != ''"
               v-html="nl2br(configHome1[0].text)">
            </p>
            <!-- <v-btn outlined @click="showMore=!showMore">
              <v-icon small>mdi-chevron-down</v-icon> en savoir plus
            </v-btn> -->
          </v-col>
        </v-row>

        <br>

        <v-row v-show="true">
          <template v-for="(product) in products">
            <v-col cols="12" sm="6" md="4" lg="3" 
                   class="pa-5 mt-5 text-center" 
                   :key="product.name">
              <router-link :to="'/product/'+product._id">
              <v-img class="mx-auto" 
                    :class="product.isCircle ? 'img-rounded' : 'img-squarded'"
                    :aspect-ratio="1/1"
                    :src="baseUrl() + product.image"></v-img>
              </router-link>
                    <span class="font-dancing" style="font-size:25px;">{{ product.name }}</span>
                    <!-- <br><small>{{ product.description.substr(0, 50) }}...</small> -->
            </v-col>
          </template>
        </v-row>
        
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12" sm="12" class="pa-0 my-5 py-5">
        <v-card elevation="0" color="transparent" class="section">
          <v-col cols="12" sm="12" class="mx-auto text-center font-flower">
            
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showProductDialog" v-if="productToShow != null"  
              :fullscreen="$vuetify.breakpoint.width < 700"
              transition="dialog-bottom-transition" max-width="600" scrollable>
      <DialogProduct :product="productToShow"></DialogProduct>
    </v-dialog>

  </div>
</template>


<script>
    import core from '../plugins/core.js'

    import DialogProduct from '@/components/main-layout/DialogProduct.vue'
    import '@/assets/css/home.css';
    
    //import { i18n } from 'vue-lang-router';

    export default {
        name: "App",
        components: {
          DialogProduct
        },
        data: function () {
            return {
                products: [],
                productToShow: false,
                showProductDialog: false,
                showMore: false,
                configHome1: null,
                configHome2: null,
                mandalaLetters: [
                  { letter: 'M', color: 'white'},
                  { letter: 'a', color: 'white'},
                  { letter: 'n', color: 'white'},
                  { letter: 'd', color: 'white'},
                  { letter: 'a', color: 'white'},
                  { letter: 'l', color: 'white'},
                  { letter: 'a', color: 'white'},
                  { letter: 's', color: 'white'},
                  { letter: ' ', color: 'white'},
                  { letter: 'O', color: 'white'},
                  { letter: 'f', color: 'white'},
                  { letter: ' ', color: 'white'},
                  { letter: 'L', color: 'white'},
                  { letter: 'i', color: 'white'},
                  { letter: 'f', color: 'white'},
                  { letter: 'e', color: 'white'},
                ],
                colors: [
                  'blue', 'teal', 'orange', 'red', 'green', 'yellow', 
                  'cyan', 'purple', 'amber', 'brown', 'indigo'
                ]
            };
        },
        mounted: function(){
          this.initData()
          this.$root.$on('closeDialogProduct', () => { this.closeDialogProduct() })
          this.$store.dispatch('app/incStat', '/home')
        },
        methods: {
            
            async initData(){
              if(this.products.length == 0)
              await this.$store.dispatch('app/fetchEntities', 
                              { entityType: "product", 
                                query: { public: true, isOnHomepage: true }, 
                                sort: { 'created' : -1 } 
                              })
              this.products = this.$store.state.app.data.product


              let config = 
               await this.$store.dispatch('app/fetchEntities', 
                              { entityType: "config", 
                                query: { key: 'HOME_TXT' }, 
                                //sort: { 'created' : -1 } 
                              })
              if(config.error == false)
                this.configHome1 = config.entities[0]

              config = 
               await this.$store.dispatch('app/fetchEntities', 
                              { entityType: "config", 
                                query: { key: 'HOME_ALERT' }, 
                                //sort: { 'created' : -1 } 
                              })
              if(config.error == false)
                this.configHome2 = config.entities[0]

              this.shrinkColor()
              this.timer = setInterval(()=>{
                this.shrinkColor()
              }, 9000)

              console.log("products", this.products)
            },
            shrinkColor(){
              this.mandalaLetters.forEach((letter)=>{
                let rand = parseInt(Math.random() * this.colors.length)
                letter.color = this.colors[rand]
              })
            },
            openDialogProduct(product){
              this.productToShow = product
              this.showProductDialog = true
            },
            closeDialogProduct(){
              this.productToShow = null
              this.showProductDialog = false
            },
            nl2br: function(str, is_xhtml){
              return core.nl2br(str, is_xhtml)
            },
            baseUrl(){ return core.baseUrl() }
        },
        computed:{
          classShowMore(){ return this.showMore ? '' : 'd-none d-md-flex' }
        }
    };
</script>

